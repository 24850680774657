import React from "react"
import { StaticImage } from "gatsby-plugin-image"
//My Components
import TopMenu from "../../components/TopMenu"

import "../../assets/css/onas.css"

const OnasTop = () => {
  return (
    <div className="top">
      <StaticImage
        src="../../assets/images/top-images/o_nas.jpg"
        alt="top"
        placeholder="tracedSVG"
        layout="fullWidth"
      ></StaticImage>
      <div className="topContainer">
        <TopMenu />
      </div>
    </div>
  )
}

export default OnasTop
