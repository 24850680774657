import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const OnasContent = () => {
  return (
    <div id="onas">
      <div className="onasElement">
        <StaticImage
          src="../../assets/images/o_nas/o_nas.png"
          alt="onas"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder">
          <h3>EKa – O NAS</h3>
          <p>
            „EKa” powstała 1999 roku, w wyniku współpracy czterech
            przedsiębiorców. Od tego czasu nieustannie staramy się wychodzić
            naprzeciw oczekiwaniom naszych klientów. Stawiamy na rozwój.
            Wyróżniają nas produkty najwyższej jakości i profesjonalna obsługa.
            Działamy w kilku branżach i reagujemy na zmiany zachodzące na rynku.
            Ale przede wszystkim cenimy człowieka. Cechuje nas indywidualne
            podejście do klientów i odpowiadanie na ich potrzeby. Doradzamy,
            pomagamy, współpracujemy. To WY jesteście dla nas najważniejsi!
          </p>
        </div>
        <div className="title">NASZA FIRMA TO:</div>
        <div className="male produkcjaMale">
          <p>
            produkcja art.
            <br />
            spożywczych
          </p>
        </div>
        <div className="male technicznyMale">
          <p>
            sklepy
            <br />
            techniczne
          </p>
        </div>
        <div className="male spozywczeMale">
          <p>
            sklepy
            <br />
            spożywcze
          </p>
        </div>
        <div className="male ozeMale">
          <p>
            ekologiczne
            <br />
            źródła energii
          </p>
        </div>
      </div>
    </div>
  )
}

export default OnasContent
