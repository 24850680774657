import React from "react"
//My Components
import Footer from "../components/Footer"
import Seo from "../components/Seo"
import OnasTop from "../components/onas/OnasTop"
import OnasContent from "../components/onas/OnasContent"
import SideMenu from "../components/SideMenu"

const Onas = () => {
  return (
    <div id="onas_top" className="page onasPage">
      <Seo title="O nas" />
      <OnasTop />
      <OnasContent />
      <SideMenu base="/o_nas#onas_top" />
      <Footer />
    </div>
  )
}

export default Onas
